<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <messageBox ref="msgBox" />

        <v-btn
          v-if="
            $store.state.hasRight('195') &&
            (orders_unique ||
              (orders_unique_customers && orders_unique_address))
          "
          :disabled="!orders_selection || loadingFilters || building_invoices"
          @click="createInvoices()"
          color="primary"
          >{{ $t("invoicesmng-create-invoice") }}</v-btn
        >

        <v-btn
          :disabled="!orders_selection || loadingFilters || building_invoices"
          v-if="!orders_unique && $store.state.hasRight('195')"
          @click="createInvoicesMultiple()"
          color="primary"
          >{{ $t("invoicesmng-create-multiple-invoices") }}</v-btn
        >

        <div class="vertical-spacer" />
        <FiltersBar
          :filters="filters"
          :disabled="loadingOrders"
          @onFiltersLoading="onFiltersLoading"
          @onFiltersLoaded="onFiltersLoaded"
          :showLoader="true"
          :OrdersFilter="true"
        />

        <base-material-card
          v-if="!loadingFilters"
          color="primary"
          icon="mdi-cube-send"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("invoicesmng-title") }}
            </div>
          </template>

          <div style="height: 10px" />
          <v-text-field
            v-model="filter"
            append-icon="mdi-magnify"
            class="mr-auto pl-1"
            :label="$t('gbl-search')"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <div class="vertical-spacer" />

          <v-data-table
            :disabled="building_invoices"
            v-model="selected_orders"
            show-select
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="orders"
            :options.sync="options"
            :server-items-length="totalOrders"
            :loading="loadingOrders"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.business_name="{ item }">
              <v-icon :color="item.reference_color">mdi-circle</v-icon>

              {{
                item.business_name
                  ? item.business_name
                  : item.first_name + " " + item.last_name
              }}
            </template>

            <template v-slot:item.practice_date="{ item }">
              {{ item.practice_date | toLocaleDate }}
            </template>

            <template v-slot:item.total_gross="{ item }">
              {{
                $options.filters.toCurrency(
                  item.price * item.quantity +
                    (item.price * item.quantity * item.vat_percent) / 100.0
                )
              }}
            </template>
            <template v-slot:item.practice_status="{ item }">
              {{ practiceStatesOptions[item.practice_status].name }}
            </template>
            <template v-slot:item.account="{ item }">
              <v-avatar
                size="36px"
                v-if="
                  AvatarServices.checkGravatar(item, 'account') &&
                  AvatarServices.getGravatar(item, 'account')
                "
              >
                <v-img
                  :src="AvatarServices.getGravatar(item, 'account')"
                  :alt="AvatarServices.getUsername(item, 'account')"
                />
              </v-avatar>

              <v-avatar
                v-else-if="AvatarServices.getProfileLetters(item, 'account')"
                color="blue"
                size="36px"
              >
                <span class="profile-text">{{
                  AvatarServices.getProfileLetters(item, "account")
                }}</span>
              </v-avatar>

              {{ AvatarServices.getUsername(item, "account") }}
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import ordersManager from "../apis/orders";
import usersManager from "../apis/users";
import AvatarServices from "../services/avatars";
import FiltersBar from "../components/FiltersBar";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      AvatarServices: AvatarServices,
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      practiceStatesOptions: staticData.practicesStates,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },

      headers: [],
      perPage: staticData.defautPerPage,
      totalOrders: 0,
      filter: "",

      filters: {
        filterByUserId: null,
        filterByCustomerId: null,
        filterByPeriod: 8,
        filterBySalesPoint: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByOrderState: null,
      },

      selected_orders: [],
      orders: [],
      loadingOrders: false,
      loadingFilters: true,
      orders_selection: false,
      orders_unique_customer: true,
      orders_unique: true,
      orders_unique_address: true,
      building_invoices: false,
      filtered_orders: [[]],
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("ordersmng-tbl-id"),
        align: "left",
        sortable: true,
        value: "id",
      },
      {
        text: this.$t("ordersmng-tbl-customer"),
        align: "left",
        sortable: true,
        value: "business_name",
      },
      {
        text: this.$t("ordersmng-tbl-practice"),
        align: "left",
        sortable: true,
        value: "practice_name",
      },
      {
        text: this.$t("ordersmng-tbl-practice-date"),
        align: "left",
        sortable: true,
        value: "practice_date",
      },
      {
        text: this.$t("ordersmng-tbl-practice-state"),
        align: "left",
        sortable: true,
        value: "practice_status",
      },
      {
        text: this.$t("ordersmng-tbl-practice-service"),
        align: "left",
        sortable: true,
        value: "service_name",
      },

      {
        text: this.$t("ordersmng-tbl-gross"),
        align: "left",
        sortable: true,
        value: "total_gross",
      },

      {
        text: this.$t("ordersmng-tbl-notes"),
        align: "left",
        sortable: true,
        value: "notes",
      },
      {
        text: this.$t("ordersmng-tbl-user"),
        align: "left",
        sortable: true,
        value: "account",
      },
    ];
  },

  components: {
    messageBox,
    FiltersBar,
  },

  watch: {
    selected_orders: {
      handler() {
        this.validateSelectedOrders();
      },
      deep: true,
    },

    options: {
      handler() {
        this.fetchServicesToInvoice();
      },
      deep: true,
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchServicesToInvoice();
        }, 300);
      },
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchServicesToInvoice();
        }, 500);
      },
      deep: true,
    },
  },

  methods: {
    buildInvoices() {
      let addresses = {};
      let customers = {};
      for (let n = 0; n < this.selected_orders.length; n++) {
        let order = this.selected_orders[n];
        let key = order.customer_id + "_" + order.shipment_address_id;
        if (!order.split_by_address)
          key = order.customer_id + "_" + order.invoice_address_id;
        if (order.split_by_address) {
          if (!addresses[key]) {
            addresses[key] = [order.id];
          } else {
            addresses[key].push(order.id);
          }
        } else {
          if (!customers[key]) {
            customers[key] = [order.id];
          } else {
            customers[key].push(order.id);
          }
        }
      }
      let ret = [];
      for (let x in customers) {
        ret.push(customers[x]);
      }
      for (let x in addresses) {
        ret.push(addresses[x]);
      }

      return ret;
    },

    createInvoices() {
      this.building_invoices = true;
      this.filtered_orders = this.buildInvoices();

      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "invoicesmng-building-invoices"
      );

      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .generateInvoices(this.filtered_orders)
            .then(() => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              this.fetchServicesToInvoice();
            })
            .catch((err) => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch((err) => {
          this.$store.state.global_overlay = false;
          this.building_invoices = false;
          console.log(err);
        });
    },

    createInvoicesMultiple() {
      this.building_invoices = true;
      this.$store.state.global_overlay = true;
      this.$store.state.global_overlay_text = this.$t(
        "invoicesmng-building-invoices"
      );
      this.filtered_orders = this.buildInvoices();
      usersManager
        .keepAlive()
        .then(() => {
          ordersManager
            .generateInvoices(this.filtered_orders)
            .then(() => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              this.fetchServicesToInvoice();
            })
            .catch((err) => {
              this.building_invoices = false;
              this.$store.state.global_overlay = false;
              console.log(err);
            });
        })
        .catch((err) => {
          this.building_invoices = false;
          this.$store.state.global_overlay = false;
          console.log(err);
        });
    },

    validateSelectedOrders() {
      // Orders should be of the same customers, also flag if they have different shipping addresses
      let firstCustomer = null;
      this.orders_selection = false;
      this.orders_unique = this.selected_orders.length <= 1 ? true : false;
      this.orders_unique_customers =
        this.selected_orders.length > 0 ? true : false;

      let addresses = {};
      this.orders_unique_address = true;
      for (let n = 0; n < this.selected_orders.length; n++) {
        this.orders_selection = true;
        let order = this.selected_orders[n];
        let key = order.customer_id + "_" + order.invoice_address_id;
        if (order.split_by_address) {
          if (!addresses[key]) addresses[key] = order.shipment_address_id;
          else {
            if (addresses[key] != order.shipment_address_id) {
              this.orders_unique_address = false;
            }
          }
        }

        if (firstCustomer == null) {
          firstCustomer = key;
          this.orders_unique_customer = true;
        } else if (key != firstCustomer) {
          this.orders_unique_customers = false;
          this.orders_unique = false;
        }
      }
    },

    onFiltersLoading() {
      this.loadingFilters = true;
    },

    onFiltersLoaded() {
      this.loadingFilters = false;
    },

    getOrderState(state) {
      return staticData.practicesStates[state].name;
    },

    getOrderPaymentState(state) {
      return staticData.ordersPaymentStates[state].name;
    },

    fetchServicesToInvoice() {
      this.selected_orders = [];
      this.loadingOrders = true;
      this.totalOrders = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.filterByUserId = this.filters.filterByUserId;
      ctx.filterByPeriod = this.filters.filterByPeriod;
      ctx.filterByCustomDateStart = this.filters.filterByCustomDateStart;
      ctx.filterByCustomDateEnd = this.filters.filterByCustomDateEnd;
      ctx.filterByCustomerId = this.filters.filterByCustomerId;
      ctx.filterByOrderState = this.filters.filterByOrderState;
      ctx.filterBySalesPoint = this.filters.filterBySalesPoint;

      ordersManager
        .fetchServicesToInvoice(ctx)
        .then((result) => {
          this.totalOrders = result.totalCount;
          this.orders = result.items;
          this.loadingOrders = false;
        })
        .catch((err) => {
          this.loadingOrders = false;
          console.log(err);
        });
    },
  },
};
</script>
