import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";

let customers = {
  fetchContactTypes: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/contacts/types",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({ types: resp.data.responseData.types });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchTypes: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/types",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({ types: resp.data.responseData.types });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFilterCustomers: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/filter",
        data: { ctx: { filter: filter } },
        method: "POST",
      })
        .then((resp) => {
          resolve({ customers: resp.data.responseData.customers });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCustomerMailAddresses: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/mailAddresses",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve({ addresses: resp.data.responseData.addresses });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCustomers: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/fetch",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCustomer: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/fetchCustomer",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addCustomer: (customer) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/add",
        data: { customer: customer },
        method: "POST",
      })
        .then((resp) => {
          resolve({ customer: resp.data.responseData.customer });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  banCustomer: (customer_id, state) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/ban",
        data: { customer_id: customer_id, state: state },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateCustomer: (customer) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/update",
        data: { customer: customer },
        method: "POST",
      })
        .then((resp) => {
          resolve({ customer: resp.data.responseData.customer });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeCustomer: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/delete",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchExposure: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/exposure",
        data: {
          id: id,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.exposures);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchShowDiscount: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/showDiscount",
        data: {
          id: id,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchTemplates: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/fetchOrdersTemplates",
        data: {
          id: id,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addOrdersTemplate: (template) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/addOrdersTemplate",
        data: template,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadOrderTemplate: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/loadOrdersTemplate",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteOrderTemplate: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/deleteOrdersTemplate",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchVatExemptions: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/fetchVatExemptions",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({ exemptions: resp.data.responseData.exemptions });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchAddresses: (customerId, unique) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/addresses/list",
        data: {
          customerId: customerId,
          unique: unique,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.addresses);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchAddressType: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/addresses/types",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({ types: resp.data.responseData.types });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCities: (region_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/addresses/cities",
        data: { region_id: region_id },
        method: "POST",
      })
        .then((resp) => {
          resolve({ items: resp.data.responseData.cities });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRegions: (nation_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/addresses/regions",
        data: { nation_id: nation_id },
        method: "POST",
      })
        .then((resp) => {
          resolve({ items: resp.data.responseData.regions });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchNations: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/addresses/nations",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({ items: resp.data.responseData.nations });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addAddress: (address) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/addresses/add",
        data: { address: address },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateAddress: (address) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/addresses/update",
        data: { address: address },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeAddress: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/addresses/delete",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchContacts: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/contacts/fetch",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateContact: (item) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/contacts/update",
        data: { item: item },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addContact: (address_id, item) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/contacts/add",
        data: { address_id: address_id, item: item },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteContact: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/contacts/delete",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCategories: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/fetchCategories",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.categories,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addCategory: (category) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/addCategory",
        data: { category: category },
        method: "POST",
      })
        .then((resp) => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateCategory: (category) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/updateCategory",
        data: { category: category },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeCategory: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/customers/deleteCategory",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadRegistryExcel: (contacts) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/customers/excel?contacts=" +
          encodeURIComponent(contacts) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadRegistryCSV: (contacts) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/customers/csv?contacts=" +
          encodeURIComponent(contacts) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },
};

export default customers;
