<template>
 <v-dialog overlay-opacity="0.6" persistent max-width="600" :value="value"> <!-- @input is required to emit emit input if clicked outside unless we use persistent -->           
  <messageBox ref="msgBox"/>
  <setString ref="setString"/>
  <v-container fill-height fluid grid-list-xl> 
    <v-layout justify-center wrap>
       <v-flex md12>
        <base-material-card class="pa-0" color="primary">
        <template v-slot:heading>
            <v-row class="pa-0">
              <v-col cols=1 class="pa-0"><v-icon large>mdi-account-multiple</v-icon></v-col>
              <v-col align="center" cols=10 class="pa-0 card-title-text" style="font-size: 25px" >{{$t('category-edit-title')}}</v-col>
              <v-col cols=1 class="pa-0 text-right"><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col>
            </v-row>
          </template>
          <v-card-text>
            <v-btn v-if="true" color="primary" @click="addCategory()">{{$t('category-edit-add')}}</v-btn>
          <v-data-table  :items-per-page="perPage" :must-sort="true" :headers="headers" :items="categories" :options.sync="options" :server-items-length="totalCategories" :loading="loading" :footer-props="dataTableFooterOptions">
            <template v-slot:item.actions="{ item }">
              <v-icon small color="primary" v-if="true" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon small color="error" v-if="true" @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
          </v-card-text>
        </base-material-card>
       </v-flex>
    </v-layout>
  </v-container>
 </v-dialog>
</template>

<script>
  import staticData from '../apis/static'
  import customersManager from '../apis/customers'

  import setString from '../components/SetString'
  import messageBox from '../components/MessageBox'

  export default {
    data() {
      return {
        dataTableFooterOptions : staticData.dataTableFooterOptions,

        currentPage: 1,
        perPage: staticData.defautPerPage,
        sortBy : "name",
        sortDesc : false,
        filter : '',
        totalCategories : 0,
        categories : [],
        headers : [
          { text : this.$t('category-edit-tbl-name') , align : 'left' , sortable : true, value: 'name'},
          { text : '', align : 'right', sortable : false, value : 'actions'}
        ],
         options : {
          sortBy: ['name'],
          sortDesc: [false]
        },
        loading : false, 
        
      }
    },

    
    components : {
      setString,
      messageBox
    },

    props : ['value'],

     watch: {
      options: {
        handler () {
         this.fetchCategories();
       },
       deep: true,
     }
    },

    mounted () {
      this.fetchCategories();
    },
    
    methods: {
      fetchCategories() {
        this.loading = true;
        this.categories = [];
        this.totalCategories = 0;
        let ctx = Object.assign({},this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        customersManager.fetchCategories(ctx).then((results)=> {
          this.totalCategories = results.totalCount;
          this.categories = results.items;
          this.loading = false;
        }).catch((err)=> {
          this.loading = false;
          console.log(err);
        })

      },

      closeDialog : function() {
        this.$emit('categoryEditingDone');
        this.$emit('input'); 
      },

      deleteItem(item)  {
          let sourceId = item.id;
          this.$refs.msgBox.show(this.$t('category-edit-remove-text'),this.$t('category-edit-remove-title')).then(() => {
            customersManager.removeCategory(sourceId).then(() => { 
              this.fetchCategories();
            }).catch((err) => {
              console.log(err);
            });
          }).catch(() => {});
        
      },

      editItem(item)  {
        let category = JSON.parse(JSON.stringify(item));
        this.$refs.setString.show(this.$t('category-edit-config-title'),this.$t('category-edit-config-hint'),this.$t('category-edit-config-text'),category.name, category.id, true,false ).then((event) => {
          category.id = event.context;
          category.name = event.value;
          customersManager.updateCategory(category).then(() => {
            this.fetchCategories();
          }).catch((err) => {
            console.log(err);
          });
        }).catch(() => {})
      },

      addCategory() {
        this.$refs.setString.show(this.$t('category-edit-add-title'),this.$t('category-edit-add-hint'),this.$t('category-edit-add-text'),this.$t('category-edit-add-default'), null, true,false ).then((event) => {
          customersManager.addCategory({name : event.value}).then(() => {
            this.fetchCategories();
          }).catch ((err) => {
            console.log(err);
          });
        }).catch(()=> {})
      },

    }
  }
</script>