<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="600" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <messageBox ref="msgBox" />
    <setString ref="setString" />
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-package-variant</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("event-edit-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <v-btn v-if="true" color="primary" @click="addEvent()">{{
                $t("event-edit-add")
              }}</v-btn>
              <v-data-table
                :items-per-page="perPage"
                :must-sort="true"
                :headers="headers"
                :items="events"
                :options.sync="options"
                :server-items-length="totalEvents"
                :loading="loading"
                :footer-props="dataTableFooterOptions"
              >
                <template v-slot:item.color="{ item }">
                  <v-text-field
                    :label="$t('event-color')"
                    outlined
                    readonly
                    hide-details
                    v-model="item.color"
                    dense
                  >
                    <template v-slot:append>
                      <v-menu
                        v-model="item.color_menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                        @input="onItemChange(item)"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="titleSwatchStyle(item)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="item.color" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu> </template
                  ></v-text-field>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon small color="primary" @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon small color="error" @click="deleteItem(item)"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import staticData from "../apis/static";
import practiceManager from "../apis/practices";

import setString from "../components/SetString";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      currentPage: 1,
      perPage: staticData.defautPerPage,
      sortBy: "name",
      sortDesc: false,
      filter: "",
      totalEvents: 0,
      events: [],
      headers: [
        {
          text: this.$t("event-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("event-edit-tbl-color"),
          align: "center",
          sortable: false,
          value: "color",
        },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      loading: false,
    };
  },

  components: {
    setString,
    messageBox,
  },

  computed: {},

  props: ["value"],

  watch: {
    options: {
      handler() {
        this.fetchEvents();
      },
      deep: true,
    },
  },

  mounted() {
    this.fetchEvents();
  },

  methods: {
    onItemChange(item) {
      if (!item.color_menu) {
        practiceManager.updateEvent(item).then(() => {
          this.fetchEvents();
        });
      }
    },
    titleSwatchStyle(item) {
      return {
        backgroundColor: item.color,
        cursor: "pointer",
        height: "25px",
        width: "25px",
        borderRadius: item.color_menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    fetchEvents() {
      this.loading = true;
      this.events = [];
      this.totalEvents = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      practiceManager
        .fetchEvents(ctx)
        .then((results) => {
          this.totalEvents = results.totalCount;

          this.events = results.items;
          for (let x of this.events) {
            x.color_menu = false;
          }

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    closeDialog: function () {
      this.$emit("eventEditingDone");
      this.$emit("input");
    },

    deleteItem(item) {
      let sourceId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("event-edit-remove-text"),
          this.$t("event-edit-remove-title")
        )
        .then(() => {
          practiceManager
            .removeEvent(sourceId)
            .then(() => {
              this.fetchEvents();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editItem(item) {
      let event = JSON.parse(JSON.stringify(item));
      this.$refs.setString
        .show(
          this.$t("event-edit-config-title"),
          this.$t("event-edit-config-hint"),
          this.$t("event-edit-config-text"),
          event.name,
          event.id,
          true,
          false
        )
        .then((event) => {
          event.id = event.context;
          event.name = event.value;
          practiceManager
            .updateEvent(event)
            .then(() => {
              this.fetchEvents();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    addEvent() {
      this.$refs.setString
        .show(
          this.$t("event-edit-add-title"),
          this.$t("event-edit-add-hint"),
          this.$t("event-edit-add-text"),
          this.$t("event-edit-add-default"),
          null,
          true,
          false
        )
        .then((event) => {
          practiceManager
            .addEvent({ name: event.value })
            .then(() => {
              this.fetchEvents();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
  },
};
</script>
