<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="1200" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <ContactEditor
        @contactConfigured="contactConfigured"
        ref="contactEditorRef"
        v-model="contactEditorDialog"
      />
      <messageBox ref="msgBox" />
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large
                    >mdi-office-building-marker-outline</v-icon
                  ></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    editingAddress
                      ? $t("address-editor-title-modify")
                      : $t("address-editor-title-add")
                  }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon v-if="!sending_changes" @click="closeDialog" large
                    >mdi-close</v-icon
                  ></v-col
                >
              </v-row>
            </template>
            <div class="vertical-spacer" />
            <v-progress-circular
              v-if="fetchingData"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>

            <ValidationObserver
              v-if="!fetchingData"
              ref="obs"
              v-slot="{ invalid, validated }"
            >
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      :label="$t('address-editor-hint-type')"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      v-model="address_type"
                      :items="address_types"
                      return-object
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    :rules="{
                      required_name_or_business: isFirstAndLastNameRequired,
                    }"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="address.first_name"
                      :label="$t('address-editor-first-name')"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    :rules="{
                      required_name_or_business: isFirstAndLastNameRequired,
                    }"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="address.last_name"
                      :label="$t('address-editor-last-name')"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    :rules="{
                      required_name_or_business: isBusinessNameRequired,
                    }"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="address.business_name"
                      :label="$t('address-editor-business-name')"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="address.email"
                    :label="$t('address-editor-email')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="address.phone"
                    :label="$t('address-editor-phone')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="address.mobile"
                    :label="$t('address-editor-mobile')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="address.fax"
                    :label="$t('address-editor-fax')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="address.address"
                      :label="$t('address-editor-address')"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      ref="nation_ref"
                      :label="$t('address-editor-hint-country')"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      v-model="address.nation_id"
                      :items="address_nations"
                      :disabled="loading_nations"
                      :loading="loading_nations"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col v-if="address.nation_id == 106">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      ref="region_ref"
                      :label="$t('address-editor-hint-region')"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      v-model="address.region_id"
                      :items="address_regions"
                      :disabled="!address.nation_id || loading_regions"
                      :loading="loading_regions"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col v-if="address.nation_id != 106">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="address.cityname"
                      :label="$t('address-editor-hint-city')"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col v-if="address.nation_id == 106">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      ref="city_ref"
                      :label="$t('address-editor-hint-city')"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      v-model="address.city_id"
                      :items="address_cities"
                      :disabled="!address.region_id || loading_cities"
                      :loading="loading_cities"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="address.postal_code"
                      :label="$t('address-editor-postal-code')"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense v-if="address_type.requires_fiscal_data">
                <v-col>
                  <ValidationProvider v-slot="{ errors, valid }">
                    <v-text-field
                      v-model="address.vat_code"
                      :label="$t('address-editor-vat-code')"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col>
                  <ValidationProvider
                    v-if="customerType == 2"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="address.fiscal_code"
                      :label="$t('address-editor-fiscal-code')"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    v-if="customerType == 1 || customerType == 3"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="address.fiscal_code"
                      :label="$t('address-editor-fiscal-code')"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row dense v-if="address_type.requires_fiscal_data">
                <v-col>
                  <v-text-field
                    v-model="address.pec_email"
                    :label="$t('address-editor-pec-email')"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="address.SPIN"
                    :label="$t('address-editor-spin')"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-card>
                    <v-toolbar height="40" color="info" dark>
                      <v-col cols="1"> </v-col
                      ><v-col cols="10">{{
                        $t("customer-editor-title-contacts")
                      }}</v-col>
                      <v-col class="text-right" cols="1">
                        <v-btn
                          v-if="$store.state.hasRight('158')"
                          icon
                          @click="addContact()"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-toolbar>
                    <v-container fluid>
                      <v-row v-if="fetchingContacts">
                        <v-col col="12">
                          <v-progress-circular
                            :indeterminate="true"
                            :rotate="0"
                            :size="32"
                            :width="4"
                            color="primary"
                          ></v-progress-circular>
                        </v-col>
                      </v-row>
                      <v-row v-if="!fetchingContacts">
                        <v-col col="12">
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  {{ $t("contact-tbl-type") }}
                                </th>
                                <th class="text-center">
                                  {{ $t("contact-tbl-name") }}
                                </th>
                                <th class="text-center">
                                  {{ $t("contact-tbl-phone") }}
                                </th>
                                <th class="text-center">
                                  {{ $t("contact-tbl-mobile") }}
                                </th>
                                <th class="text-center">
                                  {{ $t("contact-tbl-email") }}
                                </th>
                                <th class="text-center">
                                  {{ $t("contact-tbl-secondary-email") }}
                                </th>
                                <th class="text-center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in address.contacts"
                                :key="index"
                              >
                                <td class="text-center">
                                  {{ $t(item.contact_type_locale) }}
                                </td>
                                <td class="text-center">{{ item.name }}</td>
                                <td class="text-center">{{ item.phone }}</td>
                                <td class="text-center">{{ item.mobile }}</td>
                                <td class="text-center">
                                  {{ item.email }}
                                </td>
                                <td class="text-center">
                                  {{ item.secondary_email }}
                                </td>
                                <td class="text-center">
                                  <v-tooltip
                                    v-if="$store.state.hasRight('159')"
                                    bottom
                                    ><template v-slot:activator="{ on }"
                                      ><v-btn
                                        x-small
                                        text
                                        icon
                                        color="gray"
                                        @click.stop="editContact(item)"
                                        v-on="on"
                                        ><v-icon>mdi-pencil</v-icon></v-btn
                                      ></template
                                    ><span>{{
                                      $t("address-action-edit")
                                    }}</span></v-tooltip
                                  >

                                  <v-tooltip
                                    v-if="$store.state.hasRight('160')"
                                    bottom
                                    ><template v-slot:activator="{ on }"
                                      ><v-btn
                                        x-small
                                        text
                                        icon
                                        color="error"
                                        @click.stop="deleteContact(item)"
                                        v-on="on"
                                        ><v-icon
                                          >mdi-delete-circle-outline</v-icon
                                        ></v-btn
                                      ></template
                                    ><span>{{
                                      $t("address-action-delete")
                                    }}</span></v-tooltip
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>

              <div style="height: 10px" />
              <v-btn
                v-if="!sending_changes"
                width="120"
                :disabled="invalid"
                v-on:click="confirm()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-progress-circular
                v-if="sending_changes"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>

              <v-btn
                :disabled="sending_changes"
                width="120"
                v-on:click="cancel()"
                color="secondary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import customersManager from "../apis/customers";
import usersManager from "../apis/users";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ContactEditor from "./ContactEditor";
import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      address: {
        contacts: [],
      },
      customerType: null,
      editingAddress: false,
      address_type: {
        wantFiscalData: false,
      },

      fetchingContacts: false,
      fetchingData: false,
      loading_cities: false,
      loading_regions: false,
      loading_nations: false,

      address_cities: [],
      address_regions: [],
      address_nations: [],

      couriers: [],

      sending_changes: false,
      initializing_selects: false,
      contactEditorDialog: false,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    ContactEditor,
    messageBox,
  },

  props: ["value", "address_types"],

  mounted() {
    this.fetchNations();
  },

  watch: {
    address_type: {
      handler() {
        this.address.address_type = this.address_type.id;
      },
    },

    "address.vat_code": {
      handler() {
        if (
          this.address &&
          this.address.vat_code &&
          this.address.vat_code.length > 0 &&
          (!this.address.fiscal_code || this.address.fiscal_code.length < 1)
        )
          this.address.fiscal_code = this.address.vat_code;
      },
    },

    "address.nation_id": {
      handler() {
        if (this.address.nation_id) {
          if (!this.initializing_selects) {
            this.address.city_id = null;
            this.address.region_id = null;
            this.address.cityname = null;
            if (this.$refs.obs) this.$refs.obs.reset();
          }
          this.fetchRegions();
        } else {
          this.initializing_selects = false;
        }
      },
    },

    "address.region_id": {
      handler() {
        if (this.address.region_id) {
          if (!this.initializing_selects) {
            this.address.city_id = null;
            if (this.$refs.obs) this.$refs.obs.reset();
          }
          this.fetchCities();
        } else {
          this.initializing_selects = false;
        }
      },
    },
  },

  computed: {
    isBusinessNameRequired() {
      if (
        !this.address.first_name ||
        !this.address.last_name ||
        this.address.last_name.length < 1 ||
        this.address.first_name.length < 1
      )
        return true;
      return false;
    },
    isFirstAndLastNameRequired() {
      if (!this.address.business_name || this.address.business_name.length < 1)
        return true;
      return false;
    },
  },

  methods: {
    fetchContacts() {
      this.fetchingContacts = true;
      return new Promise((resolve, reject) => {
        customersManager
          .fetchContacts(this.address.id)
          .then((results) => {
            this.address.contacts = results;
            this.fetchingContacts = false;
            resolve();
          })
          .catch((err) => {
            console.log(err);
            this.fetchingContacts = false;
            reject(err);
          });
      });
    },

    fetchCouriers() {
      return new Promise((resolve, reject) => {
        this.fetchingData = true;
        usersManager
          .fetchCouriers()
          .then(async (res) => {
            this.couriers = [
              {
                id: null,
                name: this.$t("address-editor-no-default-courier"),
              },
              ...res.items,
            ];
            this.fetchingData = false;
            resolve();
          })
          .catch(async (err) => {
            console.log(err);
            this.fetchingData = false;
            reject();
          });
      });
    },

    fetchNations() {
      this.loading_nations = true;
      return new Promise((resolve, reject) => {
        customersManager
          .fetchNations()
          .then((results) => {
            this.address_nations = [
              {
                id: null,
                name: this.$t("address-editor-select-nation"),
                disabled: true,
              },
              ...results.items,
            ];
            this.loading_nations = false;
            resolve();
          })
          .catch((err) => {
            console.log(err);
            this.loading_nations = false;
            reject(err);
          });
      });
    },

    fetchRegions() {
      this.loading_regions = true;
      return new Promise((resolve, reject) => {
        customersManager
          .fetchRegions(this.address.nation_id)
          .then((results) => {
            this.address_regions = [
              {
                id: null,
                name: this.$t("address-editor-select-region"),
                disabled: true,
              },
              ...results.items,
            ];
            resolve();
            this.loading_regions = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading_regions = false;
            reject(err);
          });
      });
    },

    fetchCities() {
      this.loading_cities = true;
      return new Promise((resolve, reject) => {
        customersManager
          .fetchCities(this.address.region_id)
          .then((results) => {
            this.address_cities = [
              {
                id: null,
                name: this.$t("address-editor-select-city"),
                disabled: true,
              },
              ...results.items,
            ];
            this.loading_cities = false;
            this.initializing_selects = false;
            resolve();
          })
          .catch((err) => {
            console.log(err);
            this.loading_cities = false;
            reject(err);
          });
      });
    },

    editContact(item) {
      this.$refs.contactEditorRef.reset();
      this.contactEditorDialog = true;
      this.$refs.contactEditorRef.setContact(item, true);
    },

    deleteContact(item) {
      this.$refs.msgBox
        .show(
          this.$t("contact-delete-confirm"),
          this.$t("contact-delete-title")
        )
        .then(() => {
          this.fetchingContacts = true;
          customersManager
            .deleteContact(item.id)
            .then(() => {
              this.address.contacts = this.address.contacts.filter(function (
                v
              ) {
                return v !== item;
              });

              this.fetchingContacts = false;
            })
            .catch((err) => {
              this.fetchingContacts = false;
              console.log(err);
            });
        })
        .catch(() => {});
    },

    addContact() {
      this.$refs.contactEditorRef.reset();
      this.$refs.contactEditorRef.setContact(
        {
          type: 4,
          name: "",
          phone: "",
          mobile: "",
          email: "",
          secondary_email: "",
        },
        false
      );
      this.contactEditorDialog = true;
    },

    contactConfigured(item) {
      if (item.editingContact) {
        customersManager
          .updateContact(item)
          .then(() => {
            this.fetchContacts();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        customersManager
          .addContact(this.address.id, item)
          .then(() => {
            this.fetchContacts();
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.contactEditorDialog = false;
      this.$refs.contactEditorRef.reset();
    },

    resetModal() {
      this.sending_changes = false;
      this.address_type = this.address_types[1];

      this.address = {
        customer_id: null,
        address_type: this.address_types[1].id,
        first_name: null,
        last_name: null,
        business_name: null,
        phone: null,
        fax: null,
        mobile: null,
        vat_code: null,
        fiscal_code: null,
        address: null,
        postal_code: null,
        city_id: null,
        city: null,
        region_id: null,
        nation_id: 106,
        email: null,
        pec_email: null,
        unique_code: null,
        valid_as_shipment: false,
        default_courier: null,
        contacts: [],
      };
    },

    closeDialog() {
      this.$refs.contactEditorRef.reset();
      this.contactEditorDialog = false;
      this.$emit("input");
    },

    cancel() {
      this.closeDialog();
    },

    confirm() {
      if (!this.address.customer_id) {
        let filtered = this.address_nations.filter((x) => {
          return this.address.nation_id == x.id;
        });

        if (filtered.length && filtered[0] && filtered[0].id != null)
          this.address.nation = filtered[0].name;
        else this.address.nation = null;

        filtered = this.address_cities.filter((x) => {
          return this.address.city_id == x.id;
        });

        if (filtered.length && filtered[0] && filtered[0].id != null)
          this.address.city = filtered[0].name;
        else this.address.city = null;

        filtered = this.address_regions.filter((x) => {
          return this.address.region_id == x.id;
        });

        if (filtered.length && filtered[0] && filtered[0].id != null)
          this.address.region = filtered[0].name;
        else this.address.region = null;
      }

      this.address.type_name = this.address_type.locale_string;

      this.$emit("addressConfigured", {
        editingAddress: this.editingAddress,
        address: this.address,
      });
      this.sending_changes = true;
    },

    setItem(editing, address, customerType) {
      this.editingAddress = editing;
      this.customerType = customerType;
      if (editing) {
        this.initializing_selects = true;
        this.address = Object.assign({}, address);
        this.address.valid_as_shipment =
          this.address.valid_as_shipment == 1 ? true : false;
        this.address_type = this.address_types.filter((x) => {
          return x.id == this.address.address_type;
        })[0];

        this.fetchCouriers().then(() => {
          this.fetchContacts().then(() => {
            this.sending_changes = false;
            requestAnimationFrame(() => {
              if (this.$refs.obs) {
                this.$refs.obs.validate();
              }
            });
          });
        });
      } else {
        this.resetModal();
        this.fetchCouriers().then(() => {
          requestAnimationFrame(() => {
            if (this.$refs.obs) this.$refs.obs.reset();
            requestAnimationFrame(() => {
              this.$refs.obs.validate(); // Revalidate the bottom form observerù
            });
          });
        });
      }
    },
  },
};
</script>
