<template>
  <v-dialog persistent overlay-opacity="0.6" max-width="1600" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-dialog ref="dateDialog" v-model="dateModal" width="290px">
        <v-date-picker
          @input="saveDateDialog()"
          v-model="dateDialogValue"
          scrollable
          :first-day-of-week="1"
          :locale="$store.state.user.locale"
        >
        </v-date-picker>
      </v-dialog>

      <messageBox ref="msgBox" />
      <setCurrency ref="setCurrency" />
      <setQuantity ref="setQuantity" />
      <setString ref="setString" />

      <addService ref="addService" @addService="handleService" />
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-cube-send</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("quote-edit-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />

            <div v-if="!customer && user">
              {{ $t("quote-edit-customer") }}
              <div>
                <v-autocomplete
                  style="max-width: 500px; margin: auto"
                  dense
                  clearable
                  no-filter
                  v-model="customer"
                  :items="customers"
                  :loading="isLoadingCustomers"
                  :search-input.sync="searchCustomersInput"
                  append-icon="mdi-magnify"
                  :label="$t('quote-edit-customer-search')"
                  item-text="display_name"
                  item-value="id"
                  hide-details
                  single-line
                  outlined
                  return-object
                >
                  <template v-slot:item="data">
                    <div style="width: 100%">
                      <v-icon :color="data.item.reference_color"
                        >mdi-circle</v-icon
                      >
                      <span class="text-center body-2 font-weight-light">{{
                        data.item.name
                      }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </div>
            </div>

            <ValidationObserver
              ref="obs"
              v-slot="{ invalid, validated }"
              v-if="customer"
            >
              <v-row class="text-center" dense v-if="customer">
                <v-col>
                  {{ $t("quote-edit-customer-name") }}<BR />
                  <span class="font-weight-bold">{{
                    customer.business_name
                      ? customer.business_name
                      : customer.first_name + " " + customer.last_name
                  }}</span>
                </v-col>
                <v-col>
                  {{ $t("quote-edit-customer-price-list") }}<BR />
                  <span class="font-weight-bold">{{
                    customer.price_list
                  }}</span>
                </v-col>
              </v-row>

              <div v-if="customer">
                <div style="height: 10px" />
                <v-row>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        :immediate="true"
                        v-model="quote.name"
                        :label="$t('quote-edit-name')"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="4">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        :immediate="true"
                        @click="openDateDialog()"
                        :value="quote.creation_date | toLocaleDate"
                        :label="$t('quote-edit-select-date')"
                        :error-messages="errors"
                        :success="valid"
                        prepend-icon="mdi-calendar"
                        readonly
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :immediate="true"
                            v-model="quote.subject"
                            :label="$t('quote-edit-subject')"
                            :error-messages="errors"
                            :success="valid"
                            append-icon="mdi-chevron-down"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field
                        ></template>

                        <v-list>
                          <v-list-item
                            v-for="(option, index) in menuOptions"
                            :key="index"
                            @click="selectOption(option)"
                          >
                            <v-list-item-title>{{ option }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      :immediate="true"
                      v-model="quote.reference"
                      :label="$t('quote-edit-reference')"
                    /> </v-col
                  ><v-col cols="4">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        hide-details
                        :label="$t('quote-edit-hint-state')"
                        outlined
                        dense
                        v-model="quote.status"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-refresh"
                        :items="quoteStatesOptions"
                        :error-messages="errors"
                        :success="valid"
                      >
                      </v-select
                    ></ValidationProvider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    ><v-card outlined
                      ><v-card-subtitle
                        >Prestazioni
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              class="ml-2"
                              x-small
                              color="primary"
                              @click.stop="addService()"
                              v-on="on"
                              ><v-icon>mdi-plus</v-icon></v-btn
                            ></template
                          ><span>{{
                            $t("quotes-add-service")
                          }}</span></v-tooltip
                        ></v-card-subtitle
                      ><v-card-text>
                        <v-data-table
                          :items-per-page="perPage"
                          :must-sort="false"
                          :headers="servicesHeaders"
                          :items="quote.services"
                          :options.sync="servicesOptions"
                          :server-items-length="quote.totalServices"
                          :loading="fetchingServices"
                          :footer-props="dataTableFooterOptions"
                          disable-sort
                        >
                          <template v-slot:item.name="{ item }">
                            {{ item.name ? item.name : item.text_name }}
                            - {{ item.priority }}
                          </template>

                          <template v-slot:item.price="{ item }">
                            {{
                              item.price
                                ? $options.filters.toCurrencySymbol(item.price)
                                : item.custom_real
                                ? $options.filters.toCurrencySymbol(
                                    item.text_real_price
                                  )
                                : item.text_price
                            }}<v-icon
                              small
                              color="primary"
                              v-if="true"
                              @click="editPrice(item)"
                              >mdi-pencil</v-icon
                            >
                          </template>

                          <template v-slot:item.quantity="{ item }">
                            {{ item.quantity.toFixed(2)
                            }}<v-icon
                              small
                              color="primary"
                              v-if="true"
                              @click="editQuantity(item)"
                              >mdi-pencil</v-icon
                            >
                          </template>

                          <template v-slot:item.vat_percent="{ item }">
                            {{
                              item.vat_percent
                                ? item.vat_percent.toFixed(2)
                                : item.custom_real
                                ? item.text_real_vat.toFixed(2)
                                : item.text_vat
                            }}<v-icon
                              small
                              color="primary"
                              v-if="true"
                              @click="editVat(item)"
                              >mdi-pencil</v-icon
                            >
                          </template>
                          <template v-slot:item.total="{ item }">
                            {{
                              item.price
                                ? $options.filters.toCurrencySymbol(
                                    item.price * item.quantity +
                                      (item.price *
                                        item.quantity *
                                        item.vat_percent) /
                                        100.0
                                  )
                                : item.custom_real
                                ? $options.filters.toCurrencySymbol(
                                    item.text_real_price * item.quantity +
                                      (item.text_real_price *
                                        item.quantity *
                                        item.text_real_vat) /
                                        100.0
                                  )
                                : item.text_total
                            }}<v-icon
                              v-if="!item.price && !item.text_real_price"
                              small
                              color="primary"
                              @click="editTotal(item)"
                              >mdi-pencil</v-icon
                            >
                          </template>

                          <template v-slot:item.actions="{ item }">
                            <div style="min-width: 120px">
                              <v-tooltip
                                v-if="quote.services.indexOf(item) > 0"
                                bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="green"
                                    @click="moveUp(item)"
                                    v-on="on"
                                    ><v-icon>mdi-arrow-up</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("quote-service-move-up")
                                }}</span></v-tooltip
                              >
                              <v-tooltip
                                v-if="
                                  quote.services.indexOf(item) <
                                  quote.services.length - 1
                                "
                                bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="green"
                                    @click="moveDown(item)"
                                    v-on="on"
                                    ><v-icon>mdi-arrow-down</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("quote-service-move-down")
                                }}</span></v-tooltip
                              >
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="red"
                                    @click="deleteService(item)"
                                    v-on="on"
                                    ><v-icon>mdi-delete</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("quote-service-delete-service")
                                }}</span></v-tooltip
                              >
                            </div>
                          </template></v-data-table
                        >
                      </v-card-text></v-card
                    >
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-textarea
                      height="100"
                      outlined
                      v-model="quote.notes"
                      :label="$t('quote-edit-hint-notes')"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <div></div>

                <div style="height: 10px" />
                <v-progress-circular
                  v-if="pushing_quote"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>

                <v-btn
                  v-if="!pushing_quote"
                  width="120"
                  :disabled="!quote.creation_date || invalid"
                  v-on:click="confirm()"
                  color="primary"
                  >{{ $t("gbl-ok") }}</v-btn
                >

                <v-btn width="120" v-on:click="cancel()" color="secondary">{{
                  $t("gbl-cancel")
                }}</v-btn>
              </div>
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AvatarServices from "../services/avatars";
import customersManager from "../apis/customers";
import productsManager from "../apis/products";
import staticData from "../apis/static";
import messageBox from "../components/MessageBox";
import setCurrency from "../components/SetCurrency";
import setQuantity from "../components/SetQuantity";
import setString from "../components/SetString";
import addService from "../components/AddService";
import config from "../config";
import quotesManager from "../apis/quotes";

export default {
  data() {
    return {
      AvatarServices: AvatarServices,
      quote: {
        customer_id: null,
        user_id: null,
        status: 1,
        notes: "",
        name: null,
        subject: null,
        reference: null,
        creation_date: new Date().toISOString().substring(0, 10),
      },

      menuOptions: [
        "⁠Adempimenti Vs attività",
        "⁠⁠Adempimenti Vs immobile",
        "⁠Adempimenti HACCP e 81/08",
      ],
      menu: false, // Controls visibility of the menu
      quoteStatesOptions: staticData.quotesStates,
      customer: null,
      customers: [],
      searchCustomersInput: null,
      isLoadingCustomers: false,
      editingQuote: false,
      dateModal: false,
      dateDialogValue: null,
      forecastDateModal: false,
      forecastDateDialogValue: null,
      user: null,
      users: [],
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      attachSelectionDialog: false,
      perPage: staticData.defautPerPage,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      servicesHeaders: [
        {
          text: this.$t("quote-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("quote-edit-tbl-price"),
          align: "left",
          sortable: true,
          value: "price",
        },
        {
          text: this.$t("quote-edit-tbl-quantity"),
          align: "left",
          sortable: true,
          value: "quantity",
        },
        {
          text: this.$t("quote-edit-tbl-vat"),
          align: "left",
          sortable: true,
          value: "vat_percent",
        },
        {
          text: this.$t("quote-edit-tbl-total"),
          align: "left",
          sortable: true,
          value: "total",
        },

        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      attachmentsHeaders: [
        {
          text: this.$t("quote-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "path",
        },

        {
          text: this.$t("quote-edit-tbl-created"),
          align: "left",
          sortable: true,
          value: "created_on",
        },
        {
          text: this.$t("quote-edit-tbl-created-by"),
          align: "left",
          sortable: true,
          value: "created_by",
        },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      eventHeaders: [
        {
          text: this.$t("quote-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("quote-edit-tbl-created-on"),
          align: "left",
          sortable: true,
          value: "created_on",
        },
        {
          text: this.$t("quote-edit-tbl-assigned"),
          align: "left",
          sortable: true,
          value: "assigned_to",
        },
        {
          text: this.$t("quote-edit-tbl-expire"),
          align: "left",
          sortable: true,
          value: "expire_on",
        },
        {
          text: this.$t("quote-edit-tbl-created"),
          align: "left",
          sortable: true,
          value: "created_on",
        },
        {
          text: this.$t("quote-edit-tbl-created-by"),
          align: "left",
          sortable: true,
          value: "created_by",
        },
        {
          text: this.$t("quote-edit-tbl-completed-by"),
          align: "left",
          sortable: true,
          value: "completed_by",
        },
        {
          text: this.$t("quote-edit-tbl-completed-on"),
          align: "left",
          sortable: true,
          value: "completed_on",
        },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      eventsOptions: {
        sortBy: ["name"],
        sortDesc: [false],
        page: 1,
      },
      attachmentsOptions: {
        sortBy: ["path"],
        sortDesc: [false],
        page: 1,
      },
      servicesOptions: {
        sortBy: ["priority"],
        sortDesc: [true],
        page: 1,
      },
      headers: [
        {
          text: this.$t("quote-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name",
        },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      filter: "",
      imageCache: {},
      pushing_quote: false,
      fetchingEvents: false,
      fetchingAttachments: false,
      fetchingServices: false,
    };
  },

  props: ["value"],

  components: {
    ValidationObserver,
    ValidationProvider,
    messageBox,
    setCurrency,
    setQuantity,
    setString,
    addService,
  },

  watch: {
    eventsOptions: {
      handler() {
        this.fetchEvents();
      },
      deep: true,
    },

    attachmentsOptions: {
      handler() {
        this.fetchAttachments();
      },
      deep: true,
    },

    servicesOptions: {
      handler() {
        this.fetchServices();
      },
      deep: true,
    },

    searchCustomersInput(val) {
      clearTimeout(this._searchCustomersInput);
      if (val) {
        this._searchCustomersInput = setTimeout(() => {
          this.isLoadingCustomseters = true;
          this.fetchFiltersCustomers(val)
            .then((res) => {
              this.customers = res.customers.map((x) => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomers = false;
            })
            .catch(() => {
              this.isLoadingCustomers = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        if (!this.editingQuote) this.quote.customer_id = null;
      }
    },

    customer: {
      handler() {
        if (!this.editingQuote)
          this.quote.customer_id = this.customer ? this.customer.id : null;
        if (this.customer) {
          customersManager
            .fetchCustomer(this.quote.customer_id)
            .then(async (customer_results) => {
              customer_results.customer.days_exposure = this.quote.customer_id =
                customer_results.customer ? customer_results.customer.id : null;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
    },
  },

  mounted() {},

  computed: {},

  methods: {
    selectOption(option) {
      this.quote.subject = option;
      this.menu = false;
    },
    editPrice(item) {
      let func = this.$refs.setCurrency;
      if (!item.price) func = this.$refs.setString;
      func
        .show(
          this.$t("quote-edit-set-price-text"),
          this.$t("quote-edit-set-price-hint"),
          this.$t("quote-edit-set-price-title"),
          item.price
            ? item.price.toString().replace(".", ",")
            : item.custom_real
            ? item.text_real_price.toString().replace(".", ",")
            : item.text_price.toString().replace(".", ","),
          item,
          true,
          false
        )
        .then((event) => {
          if (item.price)
            item.price = parseFloat(event.value.replace(",", ".")).toFixed(2);
          else if (item.text_real_price)
            item.text_real_price = parseFloat(
              event.value.replace(",", ".")
            ).toFixed(2);
          else item.text_price = event.value;
          quotesManager
            .updateQuoteService(this.quote.id, item)
            .then(() => {
              this.rebuildItemPrices(item);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    editTotal(item) {
      let func = this.$refs.setCurrency;
      if (!item.price) func = this.$refs.setString;
      func
        .show(
          this.$t("quote-edit-set-price-text"),
          this.$t("quote-edit-set-price-hint"),
          this.$t("quote-edit-set-price-title"),
          item.text_total,
          item,
          true,
          false
        )
        .then((event) => {
          item.text_total = event.value;
          quotesManager
            .updateQuoteService(this.quote.id, item)
            .then(() => {
              this.rebuildItemPrices(item);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    editVat(item) {
      let func = this.$refs.setCurrency;
      if (!item.price) func = this.$refs.setString;
      func
        .show(
          this.$t("quote-edit-set-price-text"),
          this.$t("quote-edit-set-price-hint"),
          this.$t("quote-edit-set-price-title"),
          item.vat_percent
            ? item.vat_percent.toString().replace(".", ",")
            : item.custom_real
            ? item.text_real_vat.toString().replace(".", ",")
            : item.text_vat.toString().replace(".", ","),
          item,
          true,
          false
        )
        .then((event) => {
          if (item.price) {
            item.vat_percent = parseFloat(event.value.replace(",", "."));
          } else if (item.text_real_price) {
            item.text_real_vat = parseFloat(event.value.replace(",", "."));
          } else {
            item.text_vat = event.value;
          }
          quotesManager
            .updateQuoteService(this.quote.id, item)
            .then(() => {
              this.rebuildItemPrices(item);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    editQuantity(item) {
      this.$refs.setQuantity
        .show(
          this.$t("quote-edit-set-quantity-text"),
          this.$t("quote-edit-set-quantity-hint"),
          this.$t("quote-edit-set-quantity-title"),
          item.quantity.toString().replace(".", ","),
          item,
          true,
          false
        )
        .then((event) => {
          item.quantity = parseFloat(event.value.replace(",", "."));
          quotesManager
            .updateQuoteService(this.quote.id, item)
            .then(() => {
              this.rebuildItemPrices(item);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    rebuildItemPrices(item) {
      if (item.price) {
        item.price_total = (item.price * item.vat_percent) / 100.0 + item.price;
      } else if (item.custom_real) {
        item.text_real_price =
          (item.text_real_price * item.text_real_vat) / 100.0 +
          item.text_real_price;
      }
    },

    deleteService(item) {
      this.$refs.msgBox
        .show(
          this.$t("quotesmng-service-delete-text"),
          this.$t("quotesmng-service-delete-title")
        )
        .then(() => {
          quotesManager
            .deleteQuoteService(this.quote.id, item.id)
            .then(() => {
              this.fetchServices();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    moveUp(item) {
      if (this.quote.services.indexOf(item) > 0) {
        let index = this.quote.services.indexOf(item);
        let temp = this.quote.services[index];
        this.quote.services[index] = this.quote.services[index - 1];
        this.quote.services[index - 1] = temp;
        this.rebuildServicesPriority();
        quotesManager
          .flushQuoteServicesPriority(
            this.quote.services.map((x) => {
              return { id: x.id, priority: x.priority };
            }),
            this.quote.id
          )
          .then(() => {
            this.quote.services = [...this.quote.services];
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    moveDown(item) {
      if (this.quote.services.indexOf(item) < this.quote.services.length - 1) {
        let index = this.quote.services.indexOf(item);
        let temp = this.quote.services[index];
        this.quote.services[index] = this.quote.services[index + 1];
        this.quote.services[index + 1] = temp;

        this.rebuildServicesPriority();
        quotesManager
          .flushQuoteServicesPriority(
            this.quote.services.map((x) => {
              return { id: x.id, priority: x.priority };
            }),
            this.quote.id
          )
          .then(() => {
            this.quote.services = [...this.quote.services];
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    addService() {
      this.$refs.addService.show(false);
    },

    rebuildServicesPriority() {
      let priority = this.quote.services.length;
      for (let x of this.quote.services) {
        x.priority = priority;
        priority--;
      }
    },

    handleService(ev) {
      this.$refs.addService.closeDialog();
      // GET THE LOWER PRIORITY IN this.quote.services
      let lowest_priority = 0;
      for (let x of this.quote.services) {
        if (x.priority < lowest_priority) lowest_priority = x.priority;
      }

      ev.service.priority = lowest_priority - 1;
      quotesManager
        .addQuoteService(this.quote.id, ev.service)
        .then(() => {
          this.fetchServices();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchServices() {
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.servicesOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];

        this.fetchingServices = true;
        quotesManager
          .fetchServices(this.quote.id, ctx)
          .then((results) => {
            this.fetchingServices = false;
            this.quote.services = results.items;
            this.quote.totalServices = results.totalCount;
            resolve(results);
          })
          .catch((err) => {
            this.fetchingServices = false;
            console.log(err);
            reject();
          });
      });
    },

    fetchFiltersCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then((results) => {
            results.customers = results.customers.map((x) => {
              if (!x.price_list) x.price_list = this.$t("gbl-none-male");
              return x;
            });
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchProductImage(item) {
      return new Promise((resolve, reject) => {
        if (item.fetchingImage) {
          if (!this.imageCache[item.id]) {
            productsManager
              .fetchProductImage(item.id)
              .then((results) => {
                if (results.image) {
                  item.fetchedImage = "data:image/png;base64," + results.image;
                  this.imageCache[item.id] = results.image;
                  item.fetchKey++;
                  item.fetchingImage = false;
                  resolve();
                } else {
                  item.fetchedImage = null;
                  item.fetchKey++;
                  item.fetchingImage = false;
                  resolve();
                }
              })
              .catch((err) => {
                console.log(err);
                item.fetchedImage = null;
                item.fetchKey++;
                item.fetchingImage = false;
                resolve();
              });
          } else {
            item.fetchedImage =
              "data:image/png;base64," + this.imageCache[item.id];
            item.fetchingImage = false;
            item.fetchKey++;
            resolve();
          }
        } else {
          reject();
        }
      });
    },

    saveDateDialog: function () {
      this.dateModal = false;
      this.forecastDateModal = false;
      this.quote.creation_date = this.dateDialogValue;
    },

    openDateDialog: function () {
      this.dateModal = true;
    },

    confirm: function () {
      this.handleSubmit(false);
    },

    cancel: function () {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    closeDialog: function () {
      this.cancel();
    },

    handleSubmit() {
      this.$emit("quoteConfigured", {
        editingQuote: this.editingQuote,
        quote: this.quote,
      });
    },

    setModalQuote(quote, editing) {
      return new Promise(async (resolve, reject) => {
        if (!editing) {
          this.quote = Object.assign(
            {
              customer_id: null,
              user_id: null,
              status: 0,
              notes: "",
              name: null,
              subject: null,
              reference: null,
              creation_date: new Date().toISOString().substring(0, 10),
              services: [],
              totalServices: 0,
            },
            quote
          );
        } else {
          this.quote = Object.assign({}, quote);
          this.$set(this.quote, "services", []); // Reset services (they will be fetched later on
          this.quote.totalServices = 0;
        }

        this.editingQuote = editing;
        this.reset(editing)
          .then(() => {
            this.fetchServices()
              .then(() => {
                resolve();
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    reset(editing) {
      this.customer = null;

      return new Promise(async (resolve, reject) => {
        // Fetch cart items and customer details as well as addresses
        if (editing) {
          customersManager
            .fetchCustomer(this.quote.customer_id)
            .then((result) => {
              this.customer = result.customer;
              resolve();
            })
            .catch((err) => {
              reject();
              console.log(err);
            });
        }

        if (!editing) {
          this.user = {
            id: this.$store.state.user.id,
            first_name: this.$store.state.user.first_name,
            last_name: this.$store.state.user.last_name,
          };
          this.quote.user_id = this.user.id;
        } else {
          for (let n = 0; n < this.users.length; n++) {
            if (this.users[n].id == this.quote.user_id) {
              this.user = this.users[n];
              break;
            }
          }
        }
        resolve();
      });
    },
  },
};
</script>

<style scoped>
.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
