<template>
  <v-dialog overlay-opacity="0.6" max-width="800" :value="openDialog">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col
                  align="center"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ title }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <v-select
                dense
                outlined
                v-model="value"
                class="ml-auto"
                :label="label"
                :items="items"
                item-text="name"
                item-value="id"
              />
            </v-card-text>
            <v-btn width="120" @click="confirm()" :color="okvariant">{{
              $t("gbl-ok")
            }}</v-btn>
            <v-btn width="120" @click="dismiss()" :color="cancelvariant">{{
              $t("gbl-cancel")
            }}</v-btn>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      label: "",
      items: [],
      value: null,
      string_state: true,
      context: null,
      okvariant: "primary",
      cancelvariant: "secondary",
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
    };
  },

  components: {},

  methods: {
    show(
      title,
      label,
      items,
      value,
      context = null,
      okvariant = "primary",
      cancelvariant = "secondary"
    ) {
      return new Promise((resolve, reject) => {
        this.title = title;
        this.label = label;
        this.items = items;
        this.value = value;
        this.okvariant = okvariant;
        this.cancelvariant = cancelvariant;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
        this.context = context;
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      if (!this.required || this.value.length > 0) {
        if (this.value != this.initial_value || !this.mustChange) {
          this.openDialog = false;
          this.dialogResolve({ context: this.context, value: this.value });
        }
      }
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },
  },
};
</script>
