var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loadingFilters && _vm.showLoader)?_c('v-progress-circular',{attrs:{"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"primary"}}):_vm._e(),(!_vm.loadingFilters)?_c('div',[_c('v-row',{attrs:{"dense":""}},[(_vm.OrdersFilter)?_c('v-col',[_c('v-select',{ref:"period",staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('filters-period-order-state'),"items":[],"item-text":"name","item-value":"id","disabled":_vm.disabled},model:{value:(_vm.filters.filterByOrderState),callback:function ($$v) {_vm.$set(_vm.filters, "filterByOrderState", $$v)},expression:"filters.filterByOrderState"}})],1):_vm._e(),(_vm.InvoicesFilter)?_c('v-col',[_c('v-select',{staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('filters-doc-type'),"items":_vm.document_types,"item-text":"name","item-value":"id","disabled":_vm.disabled},model:{value:(_vm.filters.filterByDocumentType),callback:function ($$v) {_vm.$set(_vm.filters, "filterByDocumentType", $$v)},expression:"filters.filterByDocumentType"}})],1):_vm._e(),(_vm.InvoicesFilter)?_c('v-col',[_c('v-text-field',{staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('filters-period-invoice-number'),"value":_vm.filters.filterByDocumentsNumbers,"disabled":_vm.disabled},on:{"change":(value) => (_vm.filters.filterByDocumentsNumbers = value)}})],1):_vm._e(),(_vm.InvoicesFilter)?_c('v-col',[_c('v-select',{ref:"period",staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('filters-period-invoice-state'),"items":_vm.payment_states,"item-text":"name","item-value":"id","disabled":_vm.disabled},model:{value:(_vm.filters.filterByInvoiceState),callback:function ($$v) {_vm.$set(_vm.filters, "filterByInvoiceState", $$v)},expression:"filters.filterByInvoiceState"}})],1):_vm._e(),(
          _vm.FinancialDashboardFilter ||
          _vm.OrdersFilter ||
          _vm.InvoicesFilter ||
          _vm.OrdersReportsFilter
        )?_c('v-col',[_c('v-autocomplete',{attrs:{"disabled":_vm.loadingFilters,"clearable":"","dense":"","no-filter":"","items":_vm.flt_customers,"loading":_vm.isLoadingCustomer,"search-input":_vm.searchCustomerInput,"outlined":"","item-text":"display_name","item-value":"id","label":_vm.$t('filters-customers'),"return-object":""},on:{"update:searchInput":function($event){_vm.searchCustomerInput=$event},"update:search-input":function($event){_vm.searchCustomerInput=$event}},model:{value:(_vm.flt_customer),callback:function ($$v) {_vm.flt_customer=$$v},expression:"flt_customer"}})],1):_vm._e(),(_vm.FinancialDashboardFilter || (_vm.OrdersFilter && !_vm.CourierFilter))?_c('v-col',[_c('v-autocomplete',{attrs:{"disabled":_vm.loadingFilters,"clearable":"","dense":"","no-filter":"","items":_vm.flt_users,"loading":_vm.isLoadingUser,"search-input":_vm.searchUserInput,"outlined":"","item-text":"name","item-value":"id","label":_vm.$t('filters-users'),"return-object":""},on:{"update:searchInput":function($event){_vm.searchUserInput=$event},"update:search-input":function($event){_vm.searchUserInput=$event}},model:{value:(_vm.flt_user),callback:function ($$v) {_vm.flt_user=$$v},expression:"flt_user"}})],1):_vm._e(),(
          _vm.FinancialDashboardFilter ||
          _vm.OrdersFilter ||
          _vm.InvoicesFilter ||
          _vm.OrdersReportsFilter
        )?_c('v-col',[_c('v-select',{staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('filters-sales-point'),"items":_vm.salesPoints,"item-text":"name","item-value":"id","disabled":_vm.disabled},model:{value:(_vm.filters.filterBySalesPoint),callback:function ($$v) {_vm.$set(_vm.filters, "filterBySalesPoint", $$v)},expression:"filters.filterBySalesPoint"}})],1):_vm._e()],1),_c('v-row',{attrs:{"dense":""}},[(_vm.FinancialDashboardFilter)?_c('v-col',[_c('v-select',{staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('filters-customer-category'),"items":_vm.customerCategories,"item-text":"name","item-value":"id","disabled":_vm.disabled},model:{value:(_vm.filters.filterByCustomerCategory),callback:function ($$v) {_vm.$set(_vm.filters, "filterByCustomerCategory", $$v)},expression:"filters.filterByCustomerCategory"}})],1):_vm._e(),(_vm.FinancialDashboardFilter)?_c('v-col',[_c('v-select',{staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('filters-product-category'),"items":_vm.productCategories,"item-text":"name","item-value":"id","disabled":_vm.disabled},model:{value:(_vm.filters.filterByProductCategory),callback:function ($$v) {_vm.$set(_vm.filters, "filterByProductCategory", $$v)},expression:"filters.filterByProductCategory"}})],1):_vm._e(),(_vm.FinancialDashboardFilter)?_c('v-col',[_c('v-autocomplete',{attrs:{"disabled":_vm.loadingFilters,"clearable":"","dense":"","no-filter":"","items":_vm.flt_products,"loading":_vm.isLoadingProducts,"search-input":_vm.searchProductInput,"outlined":"","item-text":"name","item-value":"id","label":_vm.$t('filters-product'),"return-object":""},on:{"update:searchInput":function($event){_vm.searchProductInput=$event},"update:search-input":function($event){_vm.searchProductInput=$event}},model:{value:(_vm.flt_product),callback:function ($$v) {_vm.flt_product=$$v},expression:"flt_product"}})],1):_vm._e(),(
          _vm.FinancialDashboardFilter ||
          _vm.OrdersFilter ||
          _vm.InvoicesFilter ||
          _vm.OrdersReportsFilter
        )?_c('v-col',[_c('v-select',{ref:"period",staticClass:"minfield",attrs:{"outlined":"","dense":"","label":_vm.$t('filters-period-hint'),"items":_vm.getPeriodFilters,"item-text":"name","item-value":"id","disabled":_vm.disabled},model:{value:(_vm.filters.filterByPeriod),callback:function ($$v) {_vm.$set(_vm.filters, "filterByPeriod", $$v)},expression:"filters.filterByPeriod"}})],1):_vm._e(),(
          (_vm.FinancialDashboardFilter ||
            _vm.OrdersFilter ||
            _vm.InvoicesFilter ||
            _vm.OrdersReportsFilter) &&
          _vm.filters.filterByPeriod == 13
        )?_c('v-col',[_c('v-dialog',{ref:"dialog_start_date",attrs:{"return-value":_vm.filters.filterByCustomDateStart,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "filterByCustomDateStart", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "filterByCustomDateStart", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"dense":"","outlined":"","disabled":_vm.disabled,"label":_vm.$t('filters-startdate-hint'),"readonly":"","clearable":""},model:{value:(_vm.start_date_formatted),callback:function ($$v) {_vm.start_date_formatted=$$v},expression:"start_date_formatted"}},on))]}}],null,false,2649601032),model:{value:(_vm.date_start_modal),callback:function ($$v) {_vm.date_start_modal=$$v},expression:"date_start_modal"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":1,"locale":_vm.$store.state.user.locale},on:{"input":function($event){return _vm.$refs.dialog_start_date.save(_vm.filters.filterByCustomDateStart)}},model:{value:(_vm.filters.filterByCustomDateStart),callback:function ($$v) {_vm.$set(_vm.filters, "filterByCustomDateStart", $$v)},expression:"filters.filterByCustomDateStart"}})],1)],1):_vm._e(),(
          (_vm.FinancialDashboardFilter ||
            _vm.OrdersFilter ||
            _vm.InvoicesFilter ||
            _vm.OrdersReportsFilter) &&
          _vm.filters.filterByPeriod == 13
        )?_c('v-col',[_c('v-dialog',{ref:"dialog_end_date",attrs:{"return-value":_vm.filters.filterByCustomDateEnd,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "filterByCustomDateEnd", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "filterByCustomDateEnd", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"dense":"","outlined":"","disabled":_vm.disabled,"label":_vm.$t('filters-enddate-hint'),"readonly":"","clearable":""},model:{value:(_vm.end_date_formatted),callback:function ($$v) {_vm.end_date_formatted=$$v},expression:"end_date_formatted"}},on))]}}],null,false,371155624),model:{value:(_vm.date_end_modal),callback:function ($$v) {_vm.date_end_modal=$$v},expression:"date_end_modal"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":1,"locale":_vm.$store.state.user.locale},on:{"input":function($event){return _vm.$refs.dialog_end_date.save(_vm.filters.filterByCustomDateEnd)}},model:{value:(_vm.filters.filterByCustomDateEnd),callback:function ($$v) {_vm.$set(_vm.filters, "filterByCustomDateEnd", $$v)},expression:"filters.filterByCustomDateEnd"}})],1)],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }