<template>
  <v-dialog
    overlay-opacity="0.6"
    persistent
    max-width="600"
    :value="showDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-package-variant-closed</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("add-service-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon v-if="!sending_changes" @click="closeDialog" large
                    >mdi-close</v-icon
                  ></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />
            <div>
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row dense>
                  <v-col cols="10">
                    <v-autocomplete
                      clearable
                      :disabled="sending_changes"
                      dense
                      no-filter
                      v-model="flt_service"
                      :items="flt_services"
                      :loading="isLoadingService"
                      :search-input.sync="searchServiceInput"
                      outlined
                      item-text="name"
                      item-value="id"
                      :label="$t('services-service')"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        dense
                        outlined
                        :label="$t('services-quantity')"
                        v-model="service.quantity"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="!isPractice && !flt_service" dense>
                  <v-col cols="6">
                    <v-text-field
                      clearable
                      :disabled="sending_changes || flt_service != null"
                      dense
                      no-filter
                      v-model="service.text_name"
                      outlined
                      :label="$t('services-text-service')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :disabled="sending_changes || flt_service != null"
                      dense
                      no-filter
                      outlined
                      v-model="service.custom_real"
                      :items="custom_types"
                      :label="$t('services-custom-type')"
                    />
                  </v-col>
                  <v-col> </v-col>
                </v-row>
                <v-row
                  v-if="!isPractice && !flt_service && !service.custom_real"
                  dense
                >
                  <v-col cols="4">
                    <v-text-field
                      clearable
                      :disabled="sending_changes || flt_service != null"
                      dense
                      no-filter
                      v-model="service.text_price"
                      outlined
                      :label="$t('services-text-price')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      clearable
                      :disabled="sending_changes || flt_service != null"
                      dense
                      no-filter
                      v-model="service.text_vat"
                      outlined
                      :label="$t('services-text-vat')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      clearable
                      :disabled="sending_changes || flt_service != null"
                      dense
                      no-filter
                      v-model="service.text_total"
                      outlined
                      :label="$t('services-text-total')"
                    ></v-text-field>
                  </v-col>
                  <v-col> </v-col> </v-row
                ><v-row
                  v-if="!isPractice && !flt_service && service.custom_real"
                  dense
                >
                  <v-col cols="4">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :error-messages="errors"
                        :success="valid"
                        clearable
                        :disabled="sending_changes || flt_service != null"
                        dense
                        no-filter
                        v-model="service.text_real_price"
                        outlined
                        :label="$t('services-text-price-real')"
                      ></v-text-field
                    ></ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <ValidationProvider
                      rules="required|min_value:0"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :error-messages="errors"
                        :success="valid"
                        clearable
                        :disabled="sending_changes || flt_service != null"
                        dense
                        no-filter
                        v-model="service.text_real_vat"
                        outlined
                        :label="$t('services-text-vat-real')"
                      ></v-text-field
                    ></ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :error-messages="errors"
                        :success="valid"
                        clearable
                        :disabled="sending_changes || flt_service != null"
                        dense
                        no-filter
                        readonly
                        v-model="service.text_real_total"
                        outlined
                        :label="$t('services-text-total-real')"
                      ></v-text-field
                    ></ValidationProvider>
                  </v-col>
                  <v-col> </v-col>
                </v-row>
                <v-btn
                  v-if="!sending_changes"
                  width="120"
                  :disabled="(!flt_service && !service.text_name) || invalid"
                  v-on:click="confirm()"
                  color="primary"
                  >{{ $t("gbl-ok") }}</v-btn
                >

                <v-btn
                  v-if="!sending_changes"
                  width="120"
                  v-on:click="closeDialog()"
                  color="secondary"
                  >{{ $t("gbl-cancel") }}</v-btn
                >
                <v-progress-circular
                  v-if="sending_changes"
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>
              </ValidationObserver>
            </div>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import config from "../config";
import practicesManager from "../apis/practices";

import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  data() {
    return {
      sending_changes: false,
      service: {
        quantity: 1,
        id: null,
        text_name: null,
        text_price: null,
        text_vat: null,
        text_total: null,
        text_real_price: null,
        text_real_vat: null,
        text_real_total: null,
        custom_real: true,
      },
      custom_types: [
        { text: "Reale", value: true },
        { text: "Testuale", value: false },
      ],
      showDialog: false,
      isPractice: false,
      date_modal: false,
      flt_service: null,
      flt_services: [],
      searchServiceInput: null,
      isLoadingService: false,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  mounted() {
    this.reset();
  },

  watch: {
    "service.text_real_price": {
      handler(val) {
        if (val && this.service.text_real_vat) {
          this.service.text_real_total =
            parseFloat(val) * (parseFloat(this.service.text_real_vat) / 100) +
            parseFloat(val);
        } else {
          this.service.text_real_total = (0).toFixed(2);
        }
      },
    },

    "service.text_real_vat": {
      handler(val) {
        if (val && this.service.text_real_price) {
          this.service.text_real_total =
            parseFloat(this.service.text_real_price) * (val / 100) +
            parseFloat(this.service.text_real_price);
          this.service.text_real_total =
            this.service.text_real_total.toFixed(2);
        } else {
          this.service.text_real_total = (0).toFixed(2);
        }
      },
    },

    searchServiceInput(val) {
      clearTimeout(this._searchServiceInput);
      if (val) {
        this._searchServiceInput = setTimeout(() => {
          this.isLoadingService = true;
          this.fetchFilterServices(val)
            .then((res) => {
              this.flt_services = res.services;
              this.isLoadingService = false;
            })
            .catch(() => {
              this.isLoadingService = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },
  },

  methods: {
    fetchFilterServices(filter) {
      return new Promise((resolve, reject) => {
        practicesManager
          .fetchFilterServices(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    show(isPractice) {
      this.isPractice = isPractice;
      this.showDialog = true;
    },

    closeDialog() {
      this.reset();
      this.showDialog = false;
    },

    confirm() {
      this.sending_changes = true;
      if (this.flt_service) {
        this.service.id = this.flt_service.id;
        this.service.price = this.flt_service.price;
        this.service.vat_percent = this.flt_service.vat_percent;
        this.service.custom_real = false;
        this.service.text_real_price = null;
        this.service.text_real_vat = null;
        this.service.text_real_total = null;
        this.service.text_price = null;
        this.service.text_vat = null;
        this.service.text_total = null;
        this.service.text_name = null;
      } else {
        if (this.service.custom_real) {
          this.service.text_price = null;
          this.service.text_vat = null;
          this.service.text_total = null;
        } else {
          this.service.text_real_price = null;
          this.service.text_real_vat = null;
          this.service.text_real_total = null;
          this.service.text_real_total =
            this.service.text_real_price * (this.service.text_real_vat / 100) +
            this.service.text_real_price;
        }
      }

      this.$emit("addService", {
        service: this.service,
      });
    },

    cancel() {
      this.closeDialog();
    },

    reset() {
      this.sending_changes = false;
      this.service = {
        id: null,
        quantity: 1,
        vat_percent: 22,
        price: 0,
        text_name: null,
        text_price: null,
        text_vat: null,
        text_total: null,
        custom_real: true,
        text_real_price: null,
        text_real_vat: null,
        text_real_total: null,
      };
      this.flt_service = null;
      this.flt_services = [];
    },
  },
};
</script>
