<template>
  <div>
    <FinancialDashboard v-if="$store.state.hasRight('53')" />

    <ShowcaseDashboard
      v-if="$store.state.hasRight('200') && !$store.state.isAdmin()"
    />
  </div>
</template>

<script>
import FinancialDashboard from "./FinancialDashboard";
import ShowcaseDashboard from "./ShowcaseDashboard";

export default {
  data() {
    return {};
  },
  components: {
    FinancialDashboard,
    ShowcaseDashboard,
  },
};
</script>
