<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <messageBox ref="msgBox" />
        <eventEditor ref="eventEditor" v-model="eventEditorDialog" />
        <practiceEditor
          ref="practiceEditor"
          v-model="practiceEditorDialog"
          @practiceConfigured="handlePractice"
        />
        <setStringWithSelect ref="setStringWithSelect" />
        <v-dialog
          max-width="800"
          key="practiceEditorDialog"
          v-model="eventTimelineDialog"
        >
          <v-container fill-height fluid grid-list-xl>
            <v-layout justify-center wrap>
              <v-flex md12>
                <base-material-card class="pa-0" color="primary">
                  <template v-slot:heading>
                    <v-row class="pa-0">
                      <v-col cols="1" class="pa-0"
                        ><v-icon large>mdi-account</v-icon></v-col
                      >
                      <v-col
                        align="center"
                        cols="10"
                        class="pa-0 card-title-text"
                        style="font-size: 25px"
                        >{{ "Eventi pratica" }}</v-col
                      >
                      <v-col cols="1" class="pa-0 text-right"
                        ><v-icon @click="eventTimelineDialog = false" large
                          >mdi-close</v-icon
                        ></v-col
                      >
                    </v-row>
                  </template>
                  <div class="vertical-spacer" />
                  <v-progress-circular
                    v-if="fetchingEvents"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="primary"
                  ></v-progress-circular>
                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="x in practiceEvents"
                      :color="x.color"
                      small
                      :key="x.dir"
                    >
                      <v-row class="pt-1">
                        <v-col cols="4">
                          <strong>
                            {{
                              $options.filters.toLocaleDateTime(x.created_on)
                            }}</strong
                          >
                        </v-col>
                        <v-col>
                          <div>{{ x.name ? x.name : x.event_text }}</div>
                          <div>{{ x.comments }}</div>
                        </v-col>
                      </v-row>
                      <v-row align="start">
                        <v-col cols="4"
                          ><v-avatar
                            size="36px"
                            v-if="
                              AvatarServices.checkGravatar(x, 'assigned_to') &&
                              AvatarServices.getGravatar(x, 'assigned_to')
                            "
                          >
                            <v-img
                              :src="
                                AvatarServices.getGravatar(x, 'assigned_to')
                              "
                              :alt="
                                AvatarServices.getUsername(x, 'assigned_to')
                              "
                            />
                          </v-avatar>

                          <v-avatar
                            v-else-if="
                              AvatarServices.getProfileLetters(x, 'assigned_to')
                            "
                            color="blue"
                            size="36px"
                          >
                            <span class="profile-text">{{
                              AvatarServices.getProfileLetters(x, "assigned_to")
                            }}</span>
                          </v-avatar>

                          {{ AvatarServices.getUsername(x, "assigned_to") }}
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </base-material-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>

        <v-btn v-on:click="addPractice(0)" color="info">{{
          $t("practicesmng-add-practice")
        }}</v-btn>
        <v-btn v-on:click="manageEvents(0)" color="primary">{{
          $t("practice-mng-events")
        }}</v-btn>

        <base-material-card
          color="primary"
          icon="mdi-archive-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("practicesmng-title") }}
            </div>
          </template>
          <div style="height: 10px" />

          <div class="vertical-spacer" />
          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="practices"
            :options.sync="options"
            :server-items-length="totalPractices"
            :loading="loadingPractices"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.customer_name="{ item }">
              {{
                item.business_name
                  ? item.business_name
                  : item.first_name + " " + item.last_name
              }}
            </template>

            <template v-slot:item.created_by="{ item }">
              <v-avatar
                size="36px"
                v-if="
                  AvatarServices.checkGravatar(item, 'created_by') &&
                  AvatarServices.getGravatar(item, 'created_by')
                "
              >
                <v-img
                  :src="AvatarServices.getGravatar(item, 'created_by')"
                  :alt="AvatarServices.getUsername(item, 'created_by')"
                />
              </v-avatar>

              <v-avatar
                v-else-if="AvatarServices.getProfileLetters(item, 'created_by')"
                color="blue"
                size="36px"
              >
                <span class="profile-text">{{
                  AvatarServices.getProfileLetters(item, "created_by")
                }}</span>
              </v-avatar>
              {{ AvatarServices.getUsername(item, "created_by") }}
            </template>
            <template v-slot:item.status="{ item }">
              {{ practiceStatesOptions[item.status].name }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div style="min-width: 120px">
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click="changePracticeStatus(item)"
                      v-on="on"
                      ><v-icon>mdi-focus-field-vertical</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("practice-action-change-status")
                  }}</span></v-tooltip
                >

                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click="showEvents(item)"
                      v-on="on"
                      ><v-icon>mdi-target</v-icon></v-btn
                    ></template
                  ><span>{{
                    $t("practice-action-show-events")
                  }}</span></v-tooltip
                >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="primary"
                      @click="editPractice(item)"
                      v-on="on"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    ></template
                  ><span>{{ $t("practice-action-pencil") }}</span></v-tooltip
                >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on }"
                    ><v-btn
                      x-small
                      text
                      icon
                      color="error"
                      @click="deleteItem(item)"
                      v-on="on"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    ></template
                  ><span>{{ $t("practice-action-delete") }}</span></v-tooltip
                >
              </div>
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import messageBox from "../components/MessageBox";
import practicesManager from "../apis/practices";
import quotesManager from "../apis/quotes";
import practiceEditor from "../components/PracticeEditor.vue";
import eventEditor from "../components/EventEditor.vue";
import AvatarServices from "../services/avatars";
import setStringWithSelect from "../components/SetStringWithSelect";

export default {
  data() {
    return {
      AvatarServices: AvatarServices,
      practiceStatesOptions: staticData.practicesStates,
      eventTimelineDialog: false,
      fetchingEvents: false,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
      },
      practiceEvents: [],
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      perPage: staticData.defautPerPage,
      headers: [],
      practices: [],
      totalPractices: 0,
      loadingPractices: false,
      loadingData: false,
      practiceEditorDialog: false,
      eventEditorDialog: false,
    };
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("practicesmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("practicesmng-tbl-customer"),
        align: "left",
        sortable: true,
        value: "customer_name",
      },
      {
        text: this.$t("practicesmng-tbl-status"),
        align: "left",
        sortable: true,
        value: "status",
      },
      {
        text: this.$t("practicesmng-tbl-created-by"),
        align: "left",
        sortable: true,
        value: "created_by",
      },
      { value: "actions", sortable: false, text: "", width: "64px" },
    ];

    let from_quote = this.$route.params.from_quote_id;
    if (from_quote) {
      practicesManager.fetchNextId().then(async (res) => {
        await practicesManager.removePractice(res.nextId);
        let quote = await quotesManager.fetchQuote(from_quote);

        this.$refs.practiceEditor.setModalPractice(
          {
            id: res.nextId,
            customer_id: quote.customer_id,
            from_quote_id: from_quote,
          },
          false
        );
        this.practiceEditorDialog = true;
      });
    }
  },

  components: {
    messageBox,
    eventEditor,
    practiceEditor,
    setStringWithSelect,
  },

  watch: {
    options: {
      handler() {
        this.fetchPractices();
      },
      deep: true,
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (this.options.page != 1) this.options.page = 1;
          else this.fetchPractices();
        }, 300);
      },
    },

    filterByProductCategory: {
      handler() {
        this.fetchPractices();
      },
    },
  },

  methods: {
    changePracticeStatus(item) {
      let states = [...staticData.practicesStates];

      this.$refs.setStringWithSelect
        .show(
          this.$t("practice-edit-status-title"),
          this.$t("practice-edit-status-hint"),
          states,
          item.status
        )
        .then((event) => {
          practicesManager
            .updatePracticeStatus(item.id, event.value)
            .then(() => {
              this.fetchPractices();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    showEvents(item) {
      this.practiceEvents = [];
      this.eventTimelineDialog = true;
      this.fetchingEvents = true;
      let ctx = {
        sortBy: ["created_on"],
        sortDesc: [false],
      };
      practicesManager
        .fetchPracticeEvents(item.id, ctx)
        .then((results) => {
          this.practiceEvents = results.items;
          this.fetchingEvents = false;
        })
        .catch((err) => {
          this.fetchingEvents = false;
          console.log(err);
        });
    },

    fetchPractices() {
      this.loadingPractices = true;
      this.totalPractices = 0;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        ctx.filterByProductCategory = this.filterByProductCategory;
        ctx.showHidden = true;
        practicesManager
          .fetchPractices(ctx)
          .then((result) => {
            this.totalPractices = result.totalCount;
            this.practices = result.items;
            this.loadingPractices = false;
            resolve(result);
          })
          .catch((err) => {
            this.loadingPractices = false;
            console.log(err);
            reject();
          });
      });
    },

    handlePractice(event) {
      if (event.editingPractice) {
        let practice = event.practice;
        delete practice.events;
        delete practice.attachments;
        delete practice.services;
        practicesManager
          .updatePractice(practice)
          .then(() => {
            this.fetchPractices();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let practice = event.practice;
        practicesManager
          .addPractice(practice)
          .then(() => {
            this.fetchPractices();
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.practiceEditorDialog = false;
    },

    deleteItem(item) {
      let productId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("practicesmng-delete-title"),
          this.$t("practicesmng-delete-text")
        )
        .then(() => {
          practicesManager
            .removePractice(productId)
            .then(() => {
              this.fetchPractices();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editPractice(item) {
      let practice = JSON.parse(JSON.stringify(item));
      this.$refs.practiceEditor.setModalPractice(practice, true);
      this.practiceEditorDialog = true;
    },

    addPractice() {
      practicesManager.fetchNextId().then(async (res) => {
        await practicesManager.removePractice(res.nextId);

        this.$refs.practiceEditor.setModalPractice(
          {
            id: res.nextId,
          },
          false
        );
        this.practiceEditorDialog = true;
      });
    },

    manageEvents() {
      this.eventEditorDialog = true;
    },
  },
};
</script>
