<template>
  <v-dialog
    overlay-opacity="0.6"
    persistent
    max-width="600"
    :value="openDialog"
  >
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large
                    >mdi-office-building-marker-outline</v-icon
                  ></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("quote-edit-customer-search") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon @click="close" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <div class="vertical-spacer" />
            <v-row>
              <v-col>
                <v-autocomplete
                  style="max-width: 500px; margin: auto"
                  dense
                  clearable
                  no-filter
                  v-model="customer"
                  :items="customers"
                  :loading="isLoadingCustomers"
                  :search-input.sync="searchCustomersInput"
                  append-icon="mdi-magnify"
                  :label="$t('quote-edit-customer-search')"
                  item-text="display_name"
                  item-value="id"
                  hide-details
                  single-line
                  outlined
                  return-object
                >
                  <template v-slot:item="data">
                    <div style="width: 100%">
                      <v-icon :color="data.item.reference_color"
                        >mdi-circle</v-icon
                      >
                      <span class="text-center body-2 font-weight-light">{{
                        data.item.name
                      }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  width="120"
                  :disabled="!customer"
                  v-on:click="accept()"
                  color="primary"
                  >{{ $t("gbl-ok") }}</v-btn
                >

                <v-btn width="120" v-on:click="close()" color="secondary">{{
                  $t("gbl-cancel")
                }}</v-btn>
              </v-col>
            </v-row></base-material-card
          >
        </v-flex></v-layout
      >
    </v-container>
  </v-dialog>
</template>

<script>
import customersManager from "../apis/customers";
import config from "../config";

export default {
  data() {
    return {
      customer: null,
      customers: [],
      searchCustomersInput: null,
      isLoadingCustomers: false,
      openDialog: false,
      context: null,
    };
  },

  components: {},

  watch: {
    searchCustomersInput(val) {
      clearTimeout(this._searchCustomersInput);
      if (val) {
        this._searchCustomersInput = setTimeout(() => {
          this.isLoadingCustomseters = true;
          this.fetchFiltersCustomers(val)
            .then((res) => {
              this.customers = res.customers.map((x) => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomers = false;
            })
            .catch(() => {
              this.isLoadingCustomers = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },

    customer: {
      handler() {},
    },
  },

  mounted() {},

  computed: {},

  methods: {
    fetchFiltersCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then((results) => {
            results.customers = results.customers.map((x) => {
              if (!x.price_list) x.price_list = this.$t("gbl-none-male");
              return x;
            });
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    show(context) {
      this.context = context;
      this.openDialog = true;
    },

    close() {
      this.openDialog = false;
    },

    accept() {
      this.openDialog = false;
      this.$emit("customerConfigured", {
        customer_id: this.customer.id,
        context: this.context,
      });
    },
  },
};
</script>
