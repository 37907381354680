import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";
import helpers from "./helpers";
import store from "../store";

let orders = new Object({
  fetchOrderForCN: (doc_number, doc_year) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/fetchOrderForCN",
        data: { doc_number: doc_number, doc_year: doc_year },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createIntegralCreditNote: (doc_number, doc_year, memo) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/buildNC",
        data: { doc_number: doc_number, doc_year: doc_year, memo: memo },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createPartialCreditNote: (order) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/buildCustomNC",
        data: { order: order },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchInvoices: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/invoices",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.invoices,
            totalCount: resp.data.responseData.count,
            stats: resp.data.responseData.stats,
            payed: resp.data.responseData.payed,
            unpayed: resp.data.responseData.unpayed,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchOrders: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/list",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.orders,
            totalCount: resp.data.responseData.count,
            stats: resp.data.responseData.stats,
            payed: resp.data.responseData.payed,
            unpayed: resp.data.responseData.unpayed,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadOrdersShipment: (ctx, locale) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/shipments?locale=" +
          encodeURIComponent(locale) +
          "&ctx=" +
          encodeURIComponent(JSON.stringify(ctx)) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  fetchOrdersWithPayments: (ids) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/payments",
        data: { ids: ids },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.orders,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchShippedOrders: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/shipped",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.orders,
            totalCount: resp.data.responseData.count,
            stats: resp.data.responseData.stats,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchServicesToInvoice: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/toInvoice",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.orders,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchOrder: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/fetch",
        data: ctx,
        method: "POST",
      })
        .then((resp) => {
          resolve({ order: resp.data.responseData.order });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchOrderCart: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/cart",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve({ cart: resp.data.responseData.cart });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addOrder: (order) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/add",
        data: { order: order },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateOrder: (order) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/update",
        data: { order: order },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeOrder: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/delete",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeStatus: (id, status) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/changeStatus",
        data: { id: id, status: status },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeCourier: (id, courier) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/changeCourier",
        data: { id: id, courier: courier },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changePaymentStatus: (id, status) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/changePaymentStatus",
        data: { id: id, status: status },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeInvoicePaymentStatus: (orders, status) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/changeInvoicePaymentStatus",
        data: { orders: orders, status: status },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeShipmentDate: (id, shipment_date) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/changeShipmentDate",
        data: { id: id, shipment_date: shipment_date },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  generateDDT: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/generateDDT",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  generateInvoices: (customers_orders) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/generateInvoices",
        data: { customers_orders: customers_orders },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  rebuildDocument: (cn, num, year, range) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/rebuildDocument",
        data: { cn: cn, doc_num: num, doc_year: year, range: range },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeDDT: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/removeDDT",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  acquireInvoicesPaths: (ctx) => {
    if (ctx.currentPage) delete ctx.currentPage;
    if (ctx.page) delete ctx.page;
    if (ctx.perPage) delete ctx.perPage;

    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/orders/invoicesPaths",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.invoices,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadInvoice: (doc_num, doc_year, locale) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/invoice-" +
          encodeURIComponent(doc_num + "_" + doc_year) +
          ".pdf?locale=" +
          encodeURIComponent(locale) +
          "&doc_num=" +
          encodeURIComponent(doc_num) +
          "&doc_year=" +
          encodeURIComponent(doc_year) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadInvoiceDetail: (doc_num, doc_year, locale) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/invoicedetail-" +
          encodeURIComponent(doc_num + "_" + doc_year) +
          ".pdf?locale=" +
          encodeURIComponent(locale) +
          "&doc_num=" +
          encodeURIComponent(doc_num) +
          "&doc_year=" +
          encodeURIComponent(doc_year) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  printInvoice: (doc_num, doc_year, locale, spooler) => {
    return new Promise((resolve /*, reject*/) => {
      const token = localStorageService.getAccessToken();
      const url =
        config.apiEndPoint +
        "/orders/invoice-" +
        encodeURIComponent(doc_num + "_" + doc_year) +
        ".pdf?open=1&locale=" +
        encodeURIComponent(locale) +
        "&doc_num=" +
        encodeURIComponent(doc_num) +
        "&doc_year=" +
        encodeURIComponent(doc_year) +
        (spooler
          ? "&auth=" + encodeURIComponent(store.state.printer_spooler_auth)
          : "&token=" + encodeURIComponent(token));
      helpers.printURL(url, spooler, doc_num, doc_year);
      resolve();
    });
  },

  downloadInvoicesXMLZip: (ctx) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      if (ctx.currentPage) delete ctx.currentPage;
      if (ctx.page) delete ctx.page;
      if (ctx.perPage) delete ctx.perPage;

      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/invoices-xml?ctx=" +
          encodeURIComponent(JSON.stringify(ctx)) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadInvoicesExcel: (ctx) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/invoices-excel?ctx=" +
          encodeURIComponent(JSON.stringify(ctx)) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadInvoiceXml: (doc_num, doc_year, locale) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/invoice-" +
          encodeURIComponent(doc_num + "_" + doc_year) +
          ".xml?locale=" +
          encodeURIComponent(locale) +
          "&doc_num=" +
          encodeURIComponent(doc_num) +
          "&doc_year=" +
          encodeURIComponent(doc_year) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadCN: (doc_number, doc_year, locale) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/nc-" +
          encodeURIComponent(doc_number) +
          ".pdf?locale=" +
          encodeURIComponent(locale) +
          "&dn=" +
          encodeURIComponent(doc_number) +
          "&dy=" +
          encodeURIComponent(doc_year) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  printCN: (doc_number, doc_year, locale, spooler) => {
    return new Promise((resolve /*, reject*/) => {
      const token = localStorageService.getAccessToken();
      const url =
        config.apiEndPoint +
        "/orders/nc-" +
        encodeURIComponent(doc_number) +
        ".pdf?open=1&locale=" +
        encodeURIComponent(locale) +
        "&dn=" +
        encodeURIComponent(doc_number) +
        "&dy=" +
        encodeURIComponent(doc_year) +
        (spooler
          ? "&auth=" + encodeURIComponent(store.state.printer_spooler_auth)
          : "&token=" + encodeURIComponent(token));

      helpers.printURL(url, spooler, doc_number, doc_year);
      resolve();
    });
  },

  downloadCNXml: (doc_number, doc_year, locale) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/nc-" +
          encodeURIComponent(doc_number) +
          ".xml?locale=" +
          encodeURIComponent(locale) +
          "&dn=" +
          encodeURIComponent(doc_number) +
          "&dy=" +
          encodeURIComponent(doc_year) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  printDDT: (id, locale, spooler) => {
    return new Promise((resolve /*, reject*/) => {
      const token = localStorageService.getAccessToken();
      const url =
        config.apiEndPoint +
        "/orders/ddt-" +
        encodeURIComponent(id) +
        ".pdf?open=1&locale=" +
        encodeURIComponent(locale) +
        "&id=" +
        encodeURIComponent(id) +
        (spooler
          ? "&auth=" + encodeURIComponent(store.state.printer_spooler_auth)
          : "&token=" + encodeURIComponent(token));
      helpers.printURL(url, spooler, id, null);
      resolve();
    });
  },

  downloadDDT: (id, locale) => {
    return new Promise((resolve /*, reject*/) => {
      const token = localStorageService.getAccessToken();
      const link = document.createElement("a");
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/ddt-" +
          encodeURIComponent(id) +
          ".pdf?locale=" +
          encodeURIComponent(locale) +
          "&id=" +
          encodeURIComponent(id) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  printDDTWithAmounts: (id, locale, spooler) => {
    return new Promise((resolve /*, reject*/) => {
      const token = localStorageService.getAccessToken();
      const url =
        config.apiEndPoint +
        "/orders/ddta-" +
        encodeURIComponent(id) +
        ".pdf?open=1&locale=" +
        encodeURIComponent(locale) +
        "&id=" +
        encodeURIComponent(id) +
        (spooler
          ? "&auth=" + encodeURIComponent(store.state.printer_spooler_auth)
          : "&token=" + encodeURIComponent(token));

      helpers.printURL(url, spooler, id, null);
      resolve();
    });
  },

  downloadDDTWithAmounts: (id, locale) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/orders/ddta-" +
          encodeURIComponent(id) +
          ".pdf?locale=" +
          encodeURIComponent(locale) +
          "&id=" +
          encodeURIComponent(id) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },
});

export default orders;
