<template>
  <v-dialog
    overlay-opacity="0.6"
    persistent
    max-width="600"
    :value="showDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-package-variant-closed</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("add-event-title") }}</v-col
                >
                <v-col cols="1" class="pa-0"
                  ><v-icon v-if="!sending_changes" @click="closeDialog" large
                    >mdi-close</v-icon
                  ></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />
            <div>
              <v-row dense>
                <v-col>
                  <v-select
                    :disabled="sending_changes"
                    dense
                    no-filter
                    v-model="flt_event"
                    :items="flt_events"
                    :loading="isLoadingEvent"
                    outlined
                    item-text="name"
                    item-value="id"
                    :label="$t('events-event')"
                    return-object
                  ></v-select>
                </v-col>
                <v-col>
                  <v-dialog
                    ref="dialog_date"
                    v-model="date_modal"
                    :return-value.sync="event.expirationDate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="sending_changes"
                        dense
                        outlined
                        v-model="date_formatted"
                        :label="$t('event-expiration-date')"
                        readonly
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="$refs.dialog_date.save(event.expirationDate)"
                      v-model="event.expirationDate"
                      scrollable
                      :first-day-of-week="1"
                      :locale="$store.state.user.locale"
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :disabled="sending_changes"
                    v-model="event.text"
                    :label="$t('event-edit-hint-text')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <div style="height: 10px" />
              <v-btn
                v-if="!sending_changes"
                width="120"
                :disabled="!event.text && !flt_event"
                v-on:click="confirm()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >

              <v-btn
                v-if="!sending_changes"
                width="120"
                v-on:click="closeDialog()"
                color="secondary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
              <v-progress-circular
                v-if="sending_changes"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="primary"
              ></v-progress-circular>
            </div>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import config from "../config";
import practicesManager from "../apis/practices";

export default {
  data() {
    return {
      sending_changes: false,
      event: {
        expirationDate: null,
        text: null,
        id: null,
      },
      showDialog: false,
      date_modal: false,
      flt_event: null,
      flt_events: [],
      searchEventInput: null,
      isLoadingEvent: false,
    };
  },

  components: {},

  mounted() {
    this.reset();
  },

  watch: {
    searchEventInput(val) {
      clearTimeout(this._searchEventInput);
      if (val) {
        this._searchEventInput = setTimeout(() => {
          this.isLoadingEvent = true;
          this.fetchFilterEvents(val)
            .then((res) => {
              this.flt_events = res.events;
              this.isLoadingEvent = false;
            })
            .catch(() => {
              this.isLoadingEvent = false;
            });
        }, config.searchInputsFastTimeout);
      }
    },
  },

  computed: {
    date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(this.event.expirationDate);
      },
    },
  },

  methods: {
    fetchFilterEvents(filter) {
      return new Promise((resolve, reject) => {
        practicesManager
          .fetchFilterEvents(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    show() {
      this.showDialog = true;
      this.isLoadingEvent = true;
      this.fetchFilterEvents().then((res) => {
        this.flt_events = res.events;
        this.isLoadingEvent = false;
      });
    },

    closeDialog() {
      this.reset();
      this.showDialog = false;
    },

    confirm() {
      this.sending_changes = true;
      if (this.flt_event) {
        this.event.id = this.flt_event.id;
        this.event.text = null;
      } else {
        this.event.id = null;
      }

      this.$emit("addEvent", {
        event: this.event,
      });
    },

    cancel() {
      this.closeDialog();
    },

    reset() {
      this.sending_changes = false;
      this.flt_event = null;
      this.event = {
        id: null,
        text: null,
        expirationDate: null,
      };
    },
  },
};
</script>
